.table {
  thead > tr > th {
    line-height: 18px;
    font-weight: 700;
  }

  .tableRow {
    width: max-content;

    &:hover {
      cursor: pointer;
    }

    .rowItemContainer {
      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  input[type="number"] {
    -moz-appearance: textfield;
  }
  input[type="number"]:hover,
  input[type="number"]:focus {
    -moz-appearance: number-input;
  }

  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}
