.background {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  background: rgba(255, 255, 255, 0.2);
  z-index: 1201;
  backdrop-filter: blur(5px);
}

.loginFormContainer {
  position: relative;
  max-width: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  z-index: 1202;
  padding: 20px 40px;
  border-radius: 20px;
}
