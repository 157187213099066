.link {
  display: block;
  color: black;
  text-decoration: none;
  margin-top: 5px;

  span {
    font-size: 14px;
    line-height: 16px;
    padding-left: 10px;
  }
}

.openTab {
  & > div {
    background-color: #afbfc0;
  }
}

.navigationList {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;

  button {
    display: flex;
    align-items: center;
    width: 100%;
    padding: 10px 15px;
    cursor: unset;

    span {
      margin-left: 10px;
    }
  }

  div {
    width: 100%;
  }
}

.menuItemTitle {
  display: block;
  font-size: 18px;
  line-height: 20px;
  font-weight: 700;
  text-align: left;
  margin-top: 10px;
  cursor: pointer;
}
