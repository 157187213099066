@tailwind base;
@tailwind components;
@tailwind utilities;

.container {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden;
}

.contentContainer {
  padding: 84px 40px 80px 260px;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow: hidden;
}