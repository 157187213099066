.table {
  thead > tr > th {
    line-height: 20px;
    font-weight: 700;
  }

  .tableRow {
    .rowItemContainer {
      cursor: pointer;

      span {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        user-select: none;
      }

      .copyButton {
        display: none;
      }

      &:hover {
        .copyButton {
          display: block;
        }
      }
    }
  }
}
